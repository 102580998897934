<template>
  <div class="container">
    <el-row :gutter="20">
      <!-- 个人信息 -->
      <!-- 教师左侧 start -->
      <el-col :span="7" v-if="roleKey == 'teacher'">
        <div class="info-teach content">
          <h3><span></span>个人信息</h3>
          <div class="teach-text">
            <el-avatar
              v-if="$store.state.user.headUrl"
              :src="imgSrc + $store.state.user.headUrl"
              size="large"
            ></el-avatar>
            <el-avatar
              v-else
              src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
              size="large"
            ></el-avatar>
            <div class="info-user">
              <span class="info-name">{{ userInfo.userName }}</span>
              <span class="info-sex">{{
                userInfo.sex == "1" ? "男" : "女"
              }}</span>
            </div>
            <div class="info-study">
              <p>手机账号：{{ userInfo.loginName }}</p>
              <p>所在学校：{{ userInfo.dept.deptName }}</p>
            </div>
          </div>
        </div>
        <div class="class-teach content">
          <el-row type="flex" justify="space-between">
            <el-col :span="20">
              <h3><span></span>班级信息</h3>
            </el-col>
            <!-- <el-col :span="8">
                <el-select v-model="classId" placeholder="请选择" style="max-width: 116px;height: 40px;margin: 8px 0px">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
              </el-col> -->
          </el-row>
          <div class="teach-content">
            <div class="teach-contop">
              <h5>
                <img src="../../../assets/image/icons/pic-qxgl.png" alt="" />
                {{ classInfoBo.deptName }}
              </h5>
              <p>
                <span>班主任：{{ classInfoBo.leader }}</span>
                <span>班级人数：{{ classInfoBo.totalNum }}人</span>
              </p>
            </div>
            <div class="teach-conbot">
              <h5>
                <img src="../../../assets/image/icons/pic-qxfb.png" alt="" />
                班级权限
              </h5>
              <div class="teach-table">
				  <!-- :header-cell-style="tableHeaderColor"
				  cell-style="text-align: right;" -->
                <el-table
                  :data="classInfoBo._classAuthorityBos"
                  style="width: 100%"
                  
                >
                  <el-table-column prop="name" label="权限内容" width="80">
                  </el-table-column>
                  <el-table-column prop="leader" label="负责老师">
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <!-- 教师左侧 end -->

      <!-- 学生左侧 start -->
      <el-col :span="7" v-if="roleKey == 'student' || roleKey == 'parents'">
        <div class="info content">
          <el-row type="flex" justify="space-between">
            <el-col :span="10">
              <h3><span></span>个人信息</h3>
            </el-col>
            <el-col :span="8" style="text-align: right">
              <el-button
                type="text"
                style="margin: 6px 0"
                @click="goPage('/myquality/dangan')"
                >学生档案<i class="el-icon-arrow-right"></i
              ></el-button>
            </el-col>
          </el-row>
          <div class="info-text">
            <el-avatar
              v-if="$store.state.user.headUrl"
              :src="imgSrc +  $store.state.user.headUrl"
              size="large"
            ></el-avatar>
            <el-avatar
              v-else
              src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
              size="large"
            ></el-avatar>
            <div class="info-user">
              <span class="info-name">{{ userInfo.userName }}</span>
              <span class="info-sex">{{
                userInfo.sex == "1" ? "男" : "女"
              }}</span>
            </div>
            <div class="info-study">
              <span class="info-school">{{ userInfo.dept.parentName }}</span>
              <span class="info-class">{{ userInfo.dept.deptName }}</span>
            </div>
          </div>
          <div class="info-content">
            <p>身份证号：{{ userInfo.card }}</p>
            <p>学籍号（账号）：{{ userInfo.loginName }}</p>
            <p>绑定电话：{{ userInfo.phoneNumber }}</p>
            <p>班级职务：{{ userInfo.studentPosition }}</p>
          </div>
        </div>
        <div class="class content">
          <el-row type="flex" justify="space-between">
            <el-col :span="10">
              <h3><span></span>学生信用</h3>
            </el-col>
            <el-col :span="8" style="text-align: right">
              <el-button v-if="roleKey == 'student'" type="text" style="margin: 6px 0" @click="goPage('personalCenter')"
                >查看详情<i class="el-icon-arrow-right"></i
              ></el-button>
            </el-col>
          </el-row>
          <div class="class-chart" ref="circle"></div>
          <div class="chart-show">
            <h2>{{ userCreditBo.curCredit }}<span>分</span></h2>
            <p>学生信用积分</p>
          </div>
        </div>
      </el-col>
      <!-- 学生左侧 end -->

      <!-- 右侧内容 -->
      <el-col :span="17">
        <!-- 教师综素成绩 start -->
        <div class="chart content" v-if="roleKey == 'teacher'">
          <el-row type="flex" justify="space-between">
            <el-col :span="20">
              <h3><span></span>综素成绩</h3>
            </el-col>
          </el-row>
          <div class="chart-tabs">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane
                style="position: relative"
                v-for="(item, index) in classlist"
                :key="index"
                :label="item.deptName"
                :name="item.deptId"
              >
                <div class="chart-btn">
                  <el-button type="text" @click="goPage('/quality/summary')"
                    >查看详情<i class="el-icon-arrow-right"></i
                  ></el-button>
                </div>
                <div class="column" ref="column"></div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <!-- 教师综素成绩 end -->

        <!-- 学生综素成绩 start -->
        <div class="chart content" v-if="roleKey == 'student' || roleKey == 'parents'">
          <el-row type="flex" justify="space-between">
            <el-col :span="10">
              <h3><span></span>综素成绩</h3>
            </el-col>
            <el-col :span="8" style="text-align: right">
              <el-button
                type="text"
                style="margin: 6px 0"
                @click="goPage('/myquality/huizong')"
                >查看详情<i class="el-icon-arrow-right"></i
              ></el-button>
            </el-col>
          </el-row>
          <div class="columnar" ref="columnar"></div>
        </div>
        <!-- 学生综素成绩 end -->

        <div class="comprehensive">
          <el-row :gutter="20">
            <el-col :span="12">
              <!-- 教师 start -->
              <div class="comp-left content" v-if="roleKey == 'teacher'">
                <el-row type="flex" justify="space-between">
                  <el-col :span="20">
                    <h3><span></span>班级综素工作进度</h3>
                  </el-col>
                </el-row>
                <div class="comp-list">
                  <div class="comp-table">
                    <el-table
                      :data="classIndexScheduleBos"
                      style="width: 100%"
                      :header-cell-style="tableHeaderColor"
                      :cell-style="cellStyleFun"
                      max-height="433px"
                    >
                      <el-table-column prop="scheduleName" label="工作进度">
                      </el-table-column>
                      <el-table-column prop="doneNum" label="已完成" width="70">
                      </el-table-column>
                      <el-table-column
                        prop="unDoneNum"
                        label="未完成"
                        width="70"
                      >
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
              <!-- 教师 end -->

              <!-- 学生待办事项 start -->
              <div class="comp-left content" v-if="roleKey == 'student' || roleKey == 'parents'">
                <el-row type="flex" justify="space-between">
                  <el-col :span="10">
                    <h3><span></span>待办事项</h3>
                  </el-col>
                </el-row>
                <div class="comp-list" v-if="featuresSettings.length > 0">
                  <div
                    class="comp-item"
                    v-for="(item, index) in featuresSettings"
                    :key="index"
                  >
                    <span>{{ item.name }}</span>
                    <el-button v-if="roleKey == 'student'" type="text" @click="todo(item)"
                      >{{ item.nameDes }}<i class="el-icon-arrow-right"></i>
					</el-button>
                  </div>
                </div>
                <div class="comp-list comp-none" v-else>
                  <el-image
                    :src="require('../../../assets/image/icons/none-02.png')"
                  ></el-image>
                  <p>暂无待办事项</p>
                </div>
              </div>
              <!-- 学生待办事项 end -->
            </el-col>
            <el-col :span="12">
              <div class="comp-top content">
                <el-row type="flex" justify="space-between">
                  <el-col :span="10">
                    <h3><span></span>系统公示</h3>
                  </el-col>
                </el-row>
                <div class="comp-sys">
                  <p>
                    <img
                      src="../../../assets/image/icons/icon-gs-01.png"
                      alt=""
                    />
                    <span>全校学生整理遴选内容公示</span>
                  </p>
                  <div>
                    <img
                      src="../../../assets/image/icons/line-dotted.png"
                      alt=""
                    />
                  </div>
                  <p>
                    <img
                      src="../../../assets/image/icons/icon-gs-02.png"
                      alt=""
                    />
                    <span>全校学生及教师综素工作全民监督，接收质疑和复议</span>
                  </p>
                  <div>
                    <img
                      src="../../../assets/image/icons/line-dotted.png"
                      alt=""
                    />
                  </div>
                  <p>
                    <img
                      src="../../../assets/image/icons/icon-gs-03.png"
                      alt=""
                    />
                    <span>公示时间：2021-05-01——2021-06-07</span>
                  </p>
                </div>
              </div>
              <div class="comp-bottom content">
                <el-row type="flex" justify="space-between">
                  <el-col :span="10">
                    <h3><span></span>消息中心</h3>
                  </el-col>
                  <el-col :span="8" style="text-align: right">
                    <el-button
                      type="text"
                      style="margin: 6px 0"
                      @click="goPage('/message/permsg')"
                      >查看详情<i class="el-icon-arrow-right"></i
                    ></el-button>
                  </el-col>
                </el-row>
                <div class="message" v-if="messagePersonList.length > 0">
                  <div
                    class="message-item"
                    v-for="(item, index) in messagePersonList"
                    :key="index"
                  >
                    {{ item.content }}
                  </div>
                </div>
                <div class="message none" v-else>
                  <el-image
                    :src="require('../../../assets/image/icons/none-01.png')"
                  ></el-image>
                  <p>暂无消息</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getHomeList,
  getHomeTeacher,
  getTeacherClass,
} from "@/api/public/index";
import { getEvaTopics } from '@/api/student/myquality/shenxin'
export default {
  data() {
    return {
      imgSrc: this.imageSrc(),
      classId: "",
      activeName: "",
      gradeYear: "",
      classlist: [],
      roleKey: "",
      userInfo: {
        dept: {
          parentName: "",
          deptName: "",
        },
      },
      userCreditBo: {},
      studentIndexScoreBos: [],
      xAxis: [
        { product: "思想品德", 个人成绩: 0, 平均成绩: 0 },
        { product: "学业水平", 个人成绩: 0, 平均成绩: 0 },
        { product: "身心健康", 个人成绩: 0, 平均成绩: 0 },
        { product: "艺术素养", 个人成绩: 0, 平均成绩: 0 },
        { product: "社会实践", 个人成绩: 0, 平均成绩: 0 },
      ],
      teacherxAxis: [
        { product: "思想品德", 本班平均: 0, 学校平均: 0 },
        { product: "学业水平", 本班平均: 0, 学校平均: 0 },
        { product: "身心健康", 本班平均: 0, 学校平均: 0 },
        { product: "艺术素养", 本班平均: 0, 学校平均: 0 },
        { product: "社会实践", 本班平均: 0, 学校平均: 0 },
      ],
      featuresSettings: [],
      messagePersonList: [],
      classIndexScoreBo: [],
      classIndexScheduleBos: [],
      classInfoBo: {},
    };
  },
  created() {
    if (this.$store.state.user.roles.length > 0) {
      this.roleKey = this.$store.getters.roles[0].roleKey;
    }
    if (this.roleKey == "student" || this.roleKey == 'parents') {
      this.getHomeData();
    } else if (this.roleKey == "teacher") {
      this.getClass();
    }
  },
  methods: {
    goPage(url) {
		this.$router.push(url);
    },
	todo(item) {
		if (['400', '401', '402'].indexOf(item.code) != -1) {
			// 仨测评
			getEvaTopics({
				evaTypeId: item.code
			}).then(res => {
				let d = res.data
				this.$router.push({ name: "test", params: res.data })
				sessionStorage["params"] = JSON.stringify(res.data)
			})
		} else {
			this.goPage(item.route)
		}
	},
    // 教师 start
    getClass() {
      getTeacherClass({}).then((res) => {
        this.classlist = res.data;
        if (this.classlist.length > 0) {
          this.activeName = this.classlist[0].deptId;
          this.gradeYear = this.classlist[0].gradeYear;
          this.getTeacherData(this.activeName, this.gradeYear);
        }
      });
    },
    getTeacherData(deptId, gradeYear, index = 0) {
      getHomeTeacher({ deptId: deptId, gradeYear: gradeYear }).then((res) => {
        // console.log(res);
        this.userInfo = res.data.userInfo;
        this.classIndexScheduleBos = res.data.classIndexScheduleBos;
        this.classIndexScoreBo = res.data.classIndexScoreBo;
		if (res.data.classInfoBo.classAuthorityBos.length > 0) {
			res.data.classInfoBo._classAuthorityBos = []
			let temp = {}
			let d = res.data.classInfoBo.classAuthorityBos
			d.forEach(v => {
				if (temp[v.name]) {
					temp[v.name].push(v.leader)
				} else {
					temp[v.name] = [v.leader]
				}
			})
			for (let i in temp) {
				res.data.classInfoBo._classAuthorityBos.push({
					name: i,
					leader: temp[i].join(', ')
				})
			}
		}
        this.classInfoBo = res.data.classInfoBo;
        this.messagePersonList = res.data.messagePersonList;
        if (this.classIndexScoreBo.length > 0) {
          for (let i = 0; i < this.classIndexScoreBo.length; i++) {
            for (let j = 0; j < this.teacherxAxis.length; j++) {
              if (
                this.classIndexScoreBo[i].indexType ==
                this.teacherxAxis[j].product
              ) {
                this.teacherxAxis[j] = {
                  product: this.classIndexScoreBo[i].indexType,
                  本班平均: this.classIndexScoreBo[i].classAvgScore,
                  学校平均: this.classIndexScoreBo[i].schoolAvgScore,
                };
              }
            }
          }
        }
		this.$nextTick(() => {
		  this.initColumn(this.teacherxAxis, index);
		})
      });
    },
    handleClick(tab, event) {
      const index = tab.index;
      this.gradeYear = this.classlist[index].gradeYear;
      this.getTeacherData(this.activeName, this.gradeYear, index);
      // console.log(this.$refs.column[index],tab.index);
    },
    initColumn(source, index) {
      const e = this.$refs.column[index];
      var myChart = this.$echarts.init(e);
      myChart.setOption({
        legend: {},
        color: ["#198AF7", "#F7A319"],
        tooltip: {},
        grid: {
          left: "5%",
          right: "5%",
          bottom: "30",
        },
        dataset: {
          dimensions: ["product", "本班平均", "学校平均"],
          source: source,
        },
        xAxis: { type: "category" },
        yAxis: {},
        series: [
          {
            type: "bar",
            label: {
              show: true, //开启显示
              position: "top", //在内部下方显示
              color: "#000",
              fontSize: 16,
            },
          },
          {
            type: "bar",
            label: {
              show: true, //开启显示
              position: "top", //在内部下方显示
              color: "#000",
              fontSize: 16,
            },
          },
        ],
      });
    },
    // 教师 end

    // 学生 start
    getHomeData() {
      getHomeList({}).then((res) => {
        // console.log(res);
        this.userInfo = res.data.userInfo;
        // this.roleKey = this.userInfo.roles[0].roleKey
        this.userCreditBo = res.data.userCreditBo
        this.featuresSettings = res.data.featuresSettings
        this.messagePersonList = res.data.messagePersonList;
        this.studentIndexScoreBos = res.data.studentIndexScoreBos;
        if (this.studentIndexScoreBos.length > 0) {
          for (let i = 0; i < this.studentIndexScoreBos.length; i++) {
            for (let j = 0; j < this.xAxis.length; j++) {
              if (
                this.studentIndexScoreBos[i].indexType == this.xAxis[j].product
              ) {
                this.xAxis[j] = {
                  product: this.studentIndexScoreBos[i].indexType,
                  个人成绩: this.studentIndexScoreBos[i].personScore,
                  平均成绩: this.studentIndexScoreBos[i].avgScore,
                };
              }
            }
          }
        }
        this.$nextTick(() => {
          this.initCircle(this.userCreditBo);
          this.initColumnar(this.xAxis);
        });
        sessionStorage.setItem('circle', JSON.stringify(this.userCreditBo))
      });
    },
    initCircle(userCreditBo) {
      const e = this.$refs.circle;
      var myChart = this.$echarts.init(e);
      myChart.setOption({
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "3%",
          left: "center",
        },
        color: ["#F7A319", "#198AF7"],
        series: [
          {
            // hoverAnimation:false,
            // name: '访问来源',
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "20",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: userCreditBo.subCredit, name: "扣除积分" },
              { value: userCreditBo.curCredit, name: "剩余积分" },
            ],
          },
        ],
      });
    },
    initColumnar(source) {
      const e = this.$refs.columnar;
      var myChart = this.$echarts.init(e);
      myChart.setOption({
        legend: {},
        color: ["#198AF7", "#F7A319"],
        tooltip: {},
        grid: {
          left: "5%",
          right: "5%",
          bottom: "30",
        },
        dataset: {
          dimensions: ["product", "个人成绩", "平均成绩"],
          source: source,
        },
        xAxis: { type: "category" },
        yAxis: {},
        series: [
          {
            type: "bar",
            label: {
              show: true, //开启显示
              position: "top", //在内部下方显示
              color: "#000",
              fontSize: 16,
            },
          },
          {
            type: "bar",
            label: {
              show: true, //开启显示
              position: "top", //在内部下方显示
              color: "#000",
              fontSize: 16,
            },
          },
        ],
      });
    },
    // 学生 end
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F5F8FB;color: #91939A;font-weight: blod;";
      }
    },
    cellStyleFun({ row, column, rowIndex, columnIndex }) {
      if (columnIndex > 0) {
        return "text-align: center;background-color: #F5F8FB;";
      }
      return "background-color: #F5F8FB;color: #616266;font-weight: blod;";
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  margin-top: 20px;
  h3 {
    font-size: 18px;
    color: #646464;
    line-height: 20px;
    span {
      width: 6px;
      height: 20px;
      background: #198af7;
      border-radius: 3px;
      margin-right: 5px;
      float: left;
    }
  }
  .content {
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .info {
    width: 100%;
    height: 407px;
    padding: 1px 11px 19px;
    .info-text {
      text-align: center;
      border-bottom: 1px solid #ccdef4;
      .info-user {
        margin-top: 14px;
        .info-name {
          font-size: 16px;
          color: #198af7;
          font-weight: bold;
        }
        .info-sex {
          font-size: 16px;
          color: #646464;
          font-weight: bold;
          margin-left: 7px;
        }
      }
      .info-study {
        margin-top: 21px;
        margin-bottom: 19px;
        .info-school {
          font-size: 14px;
          color: #198af7;
          font-weight: 400;
        }
        .info-class {
          font-size: 14px;
          color: #198af7;
          font-weight: 400;
          margin-left: 20px;
        }
      }
    }
    .info-content {
      padding-left: 24px;
      margin-top: 8px;
      p {
        font-size: 14px;
        color: #646464;
        font-weight: 400;
      }
    }
  }
  .class {
    width: 100%;
    height: 382px;
    padding: 5px 13px 14px;
    margin-top: 20px;
    position: relative;
    .class-chart {
      width: 326px;
      height: 311px;
      margin: 0 auto;
    }
    .chart-show {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -30%);
      text-align: center;
      cursor: pointer;
      h2 {
        font-size: 48px;
        color: #198af7;
        font-weight: 400;
        margin: 9px 0;
        span {
          font-size: 18px;
        }
      }
      p {
        font-size: 14px;
        color: #198af7;
        margin: 9px 0;
      }
    }
  }
  // 教师
  .info-teach {
    width: 100%;
    height: 283px;
    padding: 1px 11px 19px;
    .teach-text {
      text-align: center;
      .info-user {
        margin-top: 14px;
        .info-name {
          font-size: 16px;
          color: #198af7;
          font-weight: bold;
        }
        .info-sex {
          font-size: 16px;
          color: #646464;
          font-weight: bold;
          margin-left: 7px;
        }
      }
      .info-study {
        margin-top: 21px;
        p {
          font-size: 14px;
          color: #646464;
          margin: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
  .class-teach {
    width: 100%;
    height: 505px;
    padding: 5px 13px 14px;
    margin-top: 20px;
    .teach-content {
      width: 100%;
      height: 433px;
      background: #f5f8fb;
      padding: 10px 0;
      box-sizing: border-box;
      border-radius: 2px;
      .teach-contop {
        border-bottom: 1px solid #ccdef4;
        padding: 0 7px;
        h5 {
          font-size: 14px;
          color: #198af7;
          margin: 0;
          img {
            vertical-align: middle;
          }
        }
        p {
          font-size: 14px;
          color: #646464;
          margin-top: 16px;
          padding-left: 3px;
          span {
            margin-right: 25px;
          }
        }
      }
      .teach-conbot {
        padding: 15px 7px 0;
        h5 {
          font-size: 14px;
          color: #198af7;
          margin: 0;
          img {
            vertical-align: middle;
          }
        }
        .teach-table {
          padding-left: 3px;
          padding-right: 5px;
        }
      }
    }
  }
  .chart {
    width: 100%;
    height: 283px;
    padding: 1px 9px;
    .columnar {
      height: 227px;
    }
    .chart-tabs {
      padding-left: 8px;
      padding-right: 8px;
      margin-top: -15px;
      .chart-btn {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1000;
      }
      .column {
        height: 190px;
      }
    }
  }
  .comprehensive {
    width: 100%;
    height: 505px;
    margin-top: 20px;
    .comp-left {
      height: 505px;
      padding: 1px 9px 17px;
      .comp-list {
        width: 100%;
        height: 438px;
        padding-left: 2px;
        padding-right: 2px;
        box-sizing: border-box;
        .comp-item {
          line-height: 40px;
          background: #f6fafe;
          padding-left: 19px;
          padding-right: 16px;
          margin-bottom: 9px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            font-size: 14px;
            color: #646464;
            font-weight: bold;
          }
          .el-button {
            color: #198af7;
          }
        }
        &.comp-none {
          background: #f6fafe;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p {
            font-size: 14px;
            color: #646464;
            margin: 0;
          }
        }
        .comp-table {
          width: 100%;
          height: 433px;
          background: #f5f8fb;
          margin-top: 5px;
          border-radius: 2px;
          box-sizing: border-box;
          padding-left: 7px;
          padding-right: 18px;
        }
      }
    }
    .comp-top {
      height: 243px;
      padding: 1px 9px;
      .comp-sys {
        padding-left: 12px;
        p {
          font-size: 14px;
          color: #198af7;
          margin: 0;
          display: flex;
          align-items: center;
          span {
            font-weight: bold;
            margin-left: 10px;
          }
        }
        div {
          height: 40px;
          padding-left: 8px;
        }
      }
    }
    .comp-bottom {
      height: 242px;
      margin-top: 20px;
      padding: 1px 9px;
      .message {
        width: 100%;
        height: 178px;
        padding-left: 10px;
        box-sizing: border-box;
        .message-item {
          width: 100%;
          height: 20px;
          font-size: 14px;
          color: #646464;
          font-weight: bold;
          margin-bottom: 15px;
        }
        &.none {
          background: #f6fafe;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-left: 0px;
          p {
            font-size: 14px;
            color: #646464;
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.el-avatar--large {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.el-table >>> .el-table__empty-block {
  background: #f5f8fb;
}
.el-table >>> td, .el-table >>> th {
  padding: 7px 0;
}
</style>